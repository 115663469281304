<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="py-8"
          title="Critical Diameter Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Critical Diameter Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/CriticalDiameterDetails-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/CriticalDiameterDetails-min.png"
              />
            </a>
            <TitleComponent
              class="py-8"
              title="Special Instructions"
              size="medium"
            />
            <table class="test-table">
              <tbody>
                <tr>
                  <th>Special Instructions</th>
                  <th>Details</th>
                </tr>
                <tr>
                  <td>Definition of Critical Diameter</td>
                  <td>
                    The critical diameter is the diameter that produces three
                    negative results with at least one positive result
                    (explosion) at the next higher increment.
                  </td>
                </tr>
                <tr>
                  <td>Typical test methodology</td>
                  <td>
                    The diameter of the test material is incremented until the
                    critical diameter is determined (see definition above).
                  </td>
                </tr>
                <tr>
                  <td>Application to process equipment</td>
                  <td>
                    The critical diameter can be used in equipment design and to
                    assess the explosion potential of existing process
                    equipment. For example, if a substance in facility piping
                    exceeds its critical diameter, the entire system will likely
                    detonate if initiated, producing blast overpressure and
                    fragments that may result in the loss of life.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to determine the critical diameter at which a
              shock initiation propagates through the sample confined within the
              pipe or process piece of equipment. In this test, a shock
              initiator is placed at the top of a pipe assembly filled with the
              test material. Pipes of varying diameters (with length of at least
              L/D = 4) are used to contain the test material. The test is
              performed by selecting a test diameter and completing three
              trials. A “+” reaction is one in which the witness place is
              shattered or holed. The test is concluded at each diameter by
              running a minimum of three successive trials which produce a “-”
              result at a diameter below a level which produces a positive
              reaction. This level is referred to as the critical diameter.
            </p>
            <p class="p-light mt-4">
              Example in-process applications where the goal is to operate under
              the critical diameter include nitroglycerin piping systems and
              rocket motors.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Booster</td>
                      <td>
                        Provide a strong, repeatable, stable shock front to the
                        top of the sample
                      </td>
                      <td>
                        <small
                          >1:1 L/D (cylindrical or conical) allows basic point
                          source initiation in the center to expand
                          hemispherically, outputting a relatively planar 200+
                          kbar shock (maximum strength); actual value is 280
                          kbar. A conical shape can produce the same effect but
                          uses 1/3 the explosive.</small
                        >
                      </td>
                      <td>
                        Composition C-4 or equivalent (e.g. Composition B), 2:1
                        L/D plus 1 in for the detonator; cylindrical boosters
                        for samples under 4 inches in diameter and conical
                        boosters for diameters 4 inches or greater.
                      </td>
                    </tr>
                    <tr>
                      <td>Substance diameter and length</td>
                      <td>
                        Sufficient length to permit stabilization or decay of
                        the shock front in the propellant; sufficient diameter
                        to identify in-process substances susceptible to
                        detonation
                      </td>
                      <td>
                        <small
                          >Length – 3:1 L/D is required to run beyond any
                          effects of the booster (established by testing in the
                          1950’s and 1960’s); 4:1 L/D is industry standard for
                          critical diameter tests, enabling discernment of the
                          shock wave generated by the propellant.</small
                        >
                      </td>
                      <td>4:1 L/D minimum</td>
                    </tr>
                    <tr>
                      <td>Confining medium</td>
                      <td>
                        Provide confinement, increasing the susceptibility of
                        the substance to detonation
                      </td>
                      <td>
                        <small
                          >Testing has demonstrated that confinement effectively
                          lowers the critical diameter of the substance,
                          increasing its susceptibility to detonation. For
                          example, a confined test of 1.5-in ID could produce
                          the same response as an unconfined 2.9-in ID.</small
                        >
                      </td>
                      <td>
                        Schedule 40 carbon steel (A53 Grade B) pipe of various
                        diameters
                      </td>
                    </tr>
                    <tr>
                      <td>Spacer</td>
                      <td>Separate the steel tube from the witness plate</td>
                      <td>
                        <small
                          >MIL-STD-1751A Method 1041: The small stand-off
                          (0.063-inch gap) between the acceptor and the witness
                          plate was introduced<br />
                          to prevent the witness plate from shattering and
                          thereby facilitate interpretation of test
                          results.</small
                        >
                      </td>
                      <td>1.6 ± 0.2mm (1/16-in) thick</td>
                    </tr>
                    <tr>
                      <td>Witness plate specifications</td>
                      <td>Evidence of reaction type</td>
                      <td>
                        <small
                          >MIL-STD-1751A Method 1041: Some laboratories prefer
                          to use witness plates with larger length and width,
                          e.g., 9″ square, to reduce the likelihood of
                          shattering the witness plate. This is an acceptable
                          variation.</small
                        >
                      </td>
                      <td>
                        Mild steel (e.g. SAE 1010 – 1026) witness plate large
                        enough to completely overlap the sample base with
                        thickness of 0.375 in.
                      </td>
                    </tr>
                    <tr>
                      <td>Substance density</td>
                      <td>
                        Determine whether the substance in its in-process form
                        is sensitive to the test stimulus
                      </td>
                      <td></td>
                      <td>
                        Substance to be tested as near as possible to the
                        in-process density
                      </td>
                    </tr>
                    <tr>
                      <td>Number of trials</td>
                      <td>
                        Sufficient to give a reasonable level of confidence in
                        the reproducibility of the test results
                      </td>
                      <td></td>
                      <td>3 trials</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Hole in the witness plate: [IP Division 1.1 if in an
                      in-process diameter greater than the critical diameter]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Witness Plate Not Holed (-)"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/CriticalDia-Go-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/CriticalDia-Go-min.png"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Witness Plate Not Holed (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166131332?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166131332"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Holed Witness Plate (+)"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/CriticalDia-NoGo-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/CriticalDia-NoGo-min.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
